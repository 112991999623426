/**
 * Prins: Views / Landing > Auth
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {setRootVar} from '../../../../shared/utils/index';

import SelectorEngine from '../../../../shared/dom/selector-engine';
import Manipulator from '../../../../shared/dom/manipulator';
import EventHandler from '../../../../shared/dom/event-handler';

const pageContext = Manipulator.getDataAttribute(document.documentElement, 'page-context');

// --------
// Let's go
// --------

document.addEventListener('DOMContentLoaded', function () {
	const container = SelectorEngine.findOne('.app-landing');

	if (container) {
		// Delay von Animationen deaktivieren.
		if (pageContext === 'logout') {
			setRootVar('_landing-duration-multiplier', '0.0', container);
		}

		const card = (container) ? SelectorEngine.findOne('.card', container) : null;
		const form = (card) ? SelectorEngine.findOne('form', card) : null;

		if (form) {
			const buttons = (card) ? SelectorEngine.find('.button', card) : null;

			let isFormSubmitted = false;

			EventHandler.on(form, 'submit', event => {
				if (isFormSubmitted) {
					event.preventDefault();
					event.stopPropagation();
				} else {
					isFormSubmitted = true;

					for (const btn of buttons) {
						if (btn.getAttribute('type') === 'submit') {
							Manipulator.setDataAttribute(btn, 'loading');
						} else {
							Manipulator.setDisabled(btn, true);
						}
					}
				}
			});
		}
	}
});
